import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { useLocation } from "@reach/router"
import CoqIcon from "../../../../static/Coq.svg"

const RentreeStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  text-align: center;
  background: ${props => props.theme.saleColor.bgColor};
  color: ${props => props.theme.saleColor.textColor};
  position: relative;
  overflow: hidden;
  min-height: 53px;
  display: flex;

  @media (max-width: 720px) {
    min-height: 50px;
  }

  @media (max-width: 480px) {
    min-height: 44px;
  }

  @media (max-width: 450px) {
    padding: 8px 10px;
  }

  @media (max-width: 350px) {
    min-height: 40px;
  }
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  display: none;
  display: block;

  @media (max-width: 767px) {
    display: block;
  }
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextBoxWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;

  .movetext {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    -webkit-animation-duration: 14s;
    animation-duration: 14s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-transform: translate3d(0, 0, 1);
    transform: translate3d(0, 0, 1);
    will-change: transform, opacity;
    z-index: 9;
  }

  .firstbox {
    -webkit-animation-name: first-message;
    animation-name: first-message;
  }
  .secondbox {
    -webkit-animation-name: second-message;
    animation-name: second-message;
    opacity: 0;
  }

  @keyframes first-message {
    0% {
      -webkit-transform: translate3d(0, 32px, 0);
      transform: translate3d(0, 32px, 0);
      opacity: 0;
    }
    5%,
    40% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    45%,
    100% {
      -webkit-transform: translate3d(0, -32px, 0);
      transform: translate3d(0, -32px, 0);
      opacity: 0;
    }
  }

  @keyframes second-message {
    0% {
      -webkit-transform: translate3d(0, 32px, 0);
      transform: translate3d(0, 32px, 0);
      opacity: 0;
    }
    20%,
    40% {
      -webkit-transform: translate3d(0, 32px, 0);
      transform: translate3d(0, 32px, 0);
      opacity: 0;
    }
    50%,
    90% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    95%,
    100% {
      -webkit-transform: translate3d(0, -32px, 0);
      transform: translate3d(0, -32px, 0);
      opacity: 0;
    }
  }
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  text-align: left;
`

const LeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};
  // border: ${props => "1px solid" + props.theme.saleColor.borderColor};
  // padding: 7px 8px 6px;
  border-radius: 10px 0px;

  span {
    font-size: 12px;
    margin-left: 5px;

    @media (max-width: 720px) {
      font-size: 11px;
    }

    @media (max-width: 400px) {
      font-size: 10px;
    }
  }

  @media (max-width: 720px) {
    font-size: 21px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    // padding: 5px 6px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 14px;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "10px" : "0px")};
  color: ${props => props.theme.saleColor.textColor};

  > span:nth-of-type(1) {
    margin: 0px 7px;
    font-size: 24px;
    font-weight: 900;
    padding: 7px 8px 6px;
    border-radius: 10px 0px;
    background: ${props => props.theme.saleColor.textColor};
    color: ${props => props.theme.saleColor.bgColor};

    @media (max-width: 720px) {
      font-size: 21px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
      padding: 5px 6px;
    }

    @media (max-width: 400px) {
      font-size: 16px;
    }

    @media (max-width: 350px) {
      font-size: 14px;
    }
  }

  span > span {
    @media (max-width: 600px) {
      display: block;
    }
  }

  sup {
    margin-right: 4px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

const SecondRightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  margin-left: ${({ marginLeft }) => (marginLeft ? "10px" : "0px")};
  color: ${props => props.theme.saleColor.textColor};

  span {
    margin-left: 5px;
    @media (max-width: 600px) {
      margin-left: 0px;
    }
  }

  sup {
    margin-right: 4px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin-left: ${({ marginLeft }) => (marginLeft ? "7px" : "0px")};
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

const SecondTextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};

  strong {
    font-weight: 900;
  }

  span:nth-of-type(1) {
    @media (max-width: 600px) {
      display: none;
    }
  }

  span:nth-of-type(2) {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    // font-size: 12px;
  }
`

const Button = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 35px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 600px) {
    display: none;
  }

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }
`

const MobileButton = styled(Link)`
  display: none;
  color: ${props => props.theme.saleColor.textColor};
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0%, -50%);
  font-weight: bold;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }

  @media (max-width: 600px) {
    display: inline-block;
    position: relative;
    right: 0px;
    top: 0px;
    transform: translate(0px, 0px);
    margin-left: 10px;
  }

  @media (max-width: 400px) {
    margin-left: 5px;
  }
`

const SecondButtonText = styled.div`
  color: ${props => props.theme.saleColor.textColor};
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 5px;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`

const SecondButton = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 5px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
    text-decoration: underline;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`

const CloseButton = styled.div`
  background: none;
  color: ${props => props.theme.saleColor.textColor};
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 20px;
  margin: 0px 0px 0px 5px;
`

const RentreeStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [activeFlag, setactiveFlag] = useState(true)
  const [shopButtonHide, setShopButtonHide] = useState(false)
  const [isLynePage, setisLynePage] = useState(false)
  const [isOldLynePage, setisOldLynePage] = useState(false)
  const [isOreiller, setisOreiller] = useState(false)
  const [isMatelas, setisMatelas] = useState(false)
  const [isSurmatelas, setisSurmatelas] = useState(false)
  const [isSommierPage, setisSommierPage] = useState(false)
  const [isHomePage, setisHomePage] = useState(false)

  const { pathname } = useLocation()

  const checkButtonVisibility = () => {
    let currentPath = ""
    let ButtonHide = false
    let isShopPage = false

    if (
      pathname === "/" ||
      pathname === `/${intl.locale}/` ||
      pathname === `/${process.env.GATSBY_CURRENCY_TYPE}/` ||
      pathname === `/${process.env.GATSBY_CURRENCY_TYPE}/${intl.locale}/`
    )
      setisHomePage(true)
    else setisHomePage(false)

    let regex = /(lyneup|lynefit|lynehome|matelas|surmatelas|oreiller|sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique|shop|panier|cart|dispositif-medical|scientific-study)/
    let lyneRegex = /(lyneup|lynefit|lynehome|wearable-technology|solutions-textiles)/
    let oldLyneRegex = /(wearable-technology-derniere-chance|solutions-textiles-derniere-chance)/
    let oreillerRegex = /(oreiller)/
    let matelasRegex = /(matelas)/
    let surMatelasRegex = /(surmatelas)/
    let sommierRegex = /(sommiers-zones|sommier-zone-original|sommier-zone-coffre|sommier-zone-bois-massif|lit-electrique)/
    if (isBrowser) {
      currentPath = window.location.pathname

      let pathArr = currentPath.split("/")

      if (
        pathArr[pathArr.length - 1] === "shop" ||
        pathArr[pathArr.length - 2] === "shop"
      ) {
        isShopPage = true
      }
      ButtonHide = regex.test(currentPath)
      setShopButtonHide(ButtonHide)
      setisLynePage(lyneRegex.test(currentPath))
      setisOldLynePage(oldLyneRegex.test(currentPath))
      setisOreiller(oreillerRegex.test(currentPath))
      setisMatelas(matelasRegex.test(currentPath))
      setisSurmatelas(surMatelasRegex.test(currentPath))
      setisSommierPage(sommierRegex.test(currentPath))
    }
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("MaiStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("MaiStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })

    $(document).ready(function() {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })
  }

  useEffect(() => {
    checkVisibilityBanner()
    // BottomBarActive()
  }, [intl.locale])

  useEffect(() => {
    isBrowser && checkButtonVisibility()
  }, [pathname])
  return (
    !LyneHomeStickyHide &&
    activeFlag && (
      <RentreeStickyBannerWrapper className="LyneHomeStickyBanner">
        {shopButtonHide ? (
          <Container>
            {isOldLynePage ? (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText>
                  <span>-80%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isLynePage ? (
              <TextBox>
                <LeftText>
                  Ventes exclusives :
                  {/* <ClockImage className="lazyload" data-src={CoqIcon} /> */}
                  {/* <span>
                    Derniers
                    <br />
                    jours
                  </span> */}
                </LeftText>
                <RightText>
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isOreiller ? (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText>
                  <span>-40%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isSurmatelas ? (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText>
                  <span>-15%</span>
                  <span>
                    sur le <span>surmatelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isMatelas ? (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>matelas + oreiller</span>
                  </span>
                </RightText>
              </TextBox>
            ) : isSommierPage ? (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText>
                  <span>-30%</span>
                  <span>
                    sur le pack <span>sommier + matelas</span>
                  </span>
                </RightText>
              </TextBox>
            ) : (
              <TextBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText marginLeft="10px">
                  Jusqu’à
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
              </TextBox>
            )}
          </Container>
        ) : (
          <Container>
            {isHomePage ? (
              <TextBoxWrapper>
                <TextBox className="firstbox movetext">
                  <LinkBox to="/shop/offres/">
                    <LinkButtonTitle>Je découvre</LinkButtonTitle>
                  </LinkBox>
                  <LeftText>Ventes exclusives :</LeftText>
                  <RightText marginLeft="10px">
                    Jusqu’à
                    <span>-50%</span>
                    <span>
                      sur le 2<sup>e</sup> <span>produit</span>
                    </span>
                  </RightText>
                  {!shopButtonHide && (
                    <Button to="/shop/offres/">J'en profite {">"}</Button>
                  )}
                  {!shopButtonHide && (
                    <MobileButton to="/shop/offres/">{">"}</MobileButton>
                  )}
                </TextBox>
                <TextBox className="secondbox movetext">
                  <SecondTextBox>
                    Ventes exclusives pour votre dos chez PERCKO
                  </SecondTextBox>
                  {/* <SecondButton to="/shop/">
                  Commandez avant le 18/12 inclus {">"}
                </SecondButton> */}
                </TextBox>
              </TextBoxWrapper>
            ) : (
              <TextBox>
                <LinkBox to="/shop/offres/">
                  <LinkButtonTitle>Je découvre</LinkButtonTitle>
                </LinkBox>
                <LeftText>Ventes exclusives :</LeftText>
                <RightText marginLeft="10px">
                  Jusqu’à
                  <span>-50%</span>
                  <span>
                    sur le 2<sup>e</sup> <span>produit</span>
                  </span>
                </RightText>
                {!shopButtonHide && (
                  <Button to="/shop/offres/">J'en profite {">"}</Button>
                )}
                {!shopButtonHide && (
                  <MobileButton to="/shop/offres/">{">"}</MobileButton>
                )}
              </TextBox>
            )}
          </Container>
        )}
        {/* <CloseButton onClick={() => hideBanner()}>×</CloseButton> */}
      </RentreeStickyBannerWrapper>
    )
  )
}

export default injectIntl(RentreeStickyBanner)
